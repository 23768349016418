<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <br>
            <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                     style="width: 500px;margin: 50px auto;" v-loading="loading">
                <el-form-item label="请选择小区" prop="villageName">
                    <el-input v-model="ruleForm.villageName" placeholder="请选择小区">
                        <el-button slot="append" icon="el-icon-search"
                                   @click.native.prevent="selectVillage()"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="请选择楼幢" prop="towerId">
                    <el-select v-model="ruleForm.towerId" placeholder="请选择楼幢" style="width: 100%;"
                               @change="selectTower">
                        <el-option
                                v-for="item in towerList"
                                :key="item.towerId"
                                :label="item.towerNum"
                                :value="item.towerId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="请选择单元" prop="unitId">
                    <el-select v-model="ruleForm.unitId" placeholder="请选择单元" style="width: 100%;" @change="selectUnit">
                        <el-option
                                v-for="item in unitList"
                                :key="item.unitId"
                                :label="item.unitName"
                                :value="item.unitId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="请选择户室" prop="houseId">
                    <el-select v-model="ruleForm.houseId" placeholder="请选择户室" style="width: 100%;">
                        <el-option
                                v-for="item in houseList"
                                :key="item.houseId"
                                :label="item.houseNum"
                                :value="item.houseId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="submitForm('ruleForm')">提交申请</el-button>
                    <el-button @click="back" size="small">返回</el-button>
                </el-form-item>
            </el-form>
            <!-- 选择小区的弹出框-->
            <el-dialog
                    title="选择小区"
                    :visible.sync="dialogVisible"
                    width="80%"
            >
                <!--小区数据展示区域-->
                <el-table
                        :data="tableData"
                        style="width:100%"
                        border
                        :stripe="true"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                >
                    <el-table-column
                            prop="villageName"
                            label="小区名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="province"
                            label="省份"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="city"
                            label="城市"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="county"
                            label="区"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="settingTime"
                            label="建立时间"
                            width="200"
                            :formatter="formatSettingTime"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="stateName"
                            label="审批状态"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="selection(scope.row)">选中</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="10"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import qs from "qs"
    import commonTitle from "../table/commonTitle";

    export default {
        name: "applyHouse",
        components: {
            commonTitle
        },
        data() {
            return {
                loading: false,
                ruleForm: {
                    villageId: '',//小区id
                    houseId: '',//户室ID
                    nickname: '',//真实姓名
                    phone: '',//电话号码
                    ownerId: '',//业主Id
                    username: '',//用户名
                    password: '',//密码
                    idCard: '',//身份证号码
                    villageName: '', //小区名称
                    towerId: '',//楼幢
                    unitId: '',//单元
                },
                rules: {
                    villageName: [
                        {required: true, message: '请选择小区', trigger: 'change'},
                    ], towerId: [
                        {required: true, message: '请选择楼幢', trigger: 'change'},
                    ], unitId: [
                        {required: true, message: '请选择单元', trigger: 'change'},
                    ], houseId: [
                        {required: true, message: '请选择户室', trigger: 'change'},
                    ], nickname: [
                        {required: true, message: '请输入真实姓名', trigger: 'change'},
                    ], phone: [
                        {required: true, message: '请输入电话号码', trigger: 'change'},
                    ],
                },
                url: this.$Config.base_server,
                dialogVisible: false, //控制是否显示弹出框
                tableData: [], //小区数据集合
                multipleSelection: [],
                totalNum: 10,
                towerList: [],//楼幢数据集合
                unitList: [],//单元数据集合
                houseList: [],//户室数据
            }
        }, mounted() {
            this.common();
        }, methods: {
            common() {
                const _this = this;
                //初始化数据 查询当前业主的信息 + JSON.parse(localStorage.getItem("user")).id
                const id = JSON.parse(localStorage.getItem("user")).id;
                $.ajax({
                    url: _this.url + '/api-public/opeOwner/findById/' + id,
                    data: {access_token: localStorage.getItem("token")},
                    type: 'GET',
                    success: function (res) {
                        //赋值真实姓名
                        _this.ruleForm.nickname = res.ownerName;
                        //赋值电话号码
                        _this.ruleForm.phone = res.phone;
                        //赋值业主id
                        _this.ruleForm.ownerId = res.ownerId;
                        //赋值用户名
                        _this.ruleForm.username = res.username;
                        //赋值密码
                        _this.ruleForm.password = res.password;
                        //赋值身份证号码
                        _this.ruleForm.idCard = res.idCard;
                    }
                });
            }, selectVillage(currentPage = 0) {
                //选择小区信息
                this.dialogVisible = true;
                this.loading = true;
                //查询小区信息
                const data = {
                    access_token: localStorage.getItem("token"),
                    actResult: '1', //审批通过的小区
                    page: currentPage,
                    limit: 10,
                    owner: currentPage
                };
                const that = this;
                // $.ajax({
                //     url: that.url + "/api-public/opevillage/list",
                //     data: da,
                //     type: "get",
                //     contentType: 'application/json',
                //     success: function (res) {
                //         that.loading = false;
                //         if (res.code == 0) {
                //             that.totalNum = res.count;
                //             that.tableData = res.data;
                //         }
                //     }
                // });
                $.ajax({
                    url: that.url + "/api-public/opevillage/list",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        that.loading = false;
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    }
                });
            }, back() {
                //返回按钮
                this.$router.go(-1);
            }, handleCurrentChange(val) {//当前页数改变
                this.selectVillage(val)
            }, formatSettingTime(row) {
                //格式化时间
                var time = row.settingTime;
                var d = new Date(time);
                var times = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                return times;
            }, selection(row) {
                //选中
                this.ruleForm.villageId = row.villageId;//赋值小区id
                this.ruleForm.villageName = row.villageName;//赋职小区名称
                //清除下拉框中已经有的数据
                this.ruleForm.towerId = null;
                this.towerList = [];
                this.ruleForm.unitId = null;
                this.unitList = [];
                this.ruleForm.houseId = null;
                this.houseList = [];
                //查询该小区下面的楼幢数据
                const da = {
                    access_token: localStorage.getItem("token"),
                    searchKey: 'villageId',
                    searchValue: row.villageId
                };
                //请求后台楼幢数据
                var _this = this;
                $.ajax({
                    url: _this.url + "/api-public/opetower/list",
                    type: 'GET',
                    data: qs.stringify(da),
                    success: function (res) {
                        if (res.code == 0) {
                            _this.towerList = res.data;
                        }
                    }
                });
                this.dialogVisible = false;
            }, selectTower() {
                //清除下拉框中的数据
                this.ruleForm.unitId = null;
                this.unitList = [];
                this.ruleForm.houseId = null;
                this.houseList = [];
                //选中楼幢事件
                //查询楼幢下面的单元信息
                const da = {
                    access_token: localStorage.getItem("token"),
                    searchKey: 'towerId',
                    searchValue: this.ruleForm.towerId
                };
                const _this = this;
                //请求数据
                $.ajax({
                    url: _this.url + "/api-public/opeunit/list",
                    type: 'GET',
                    data: qs.stringify(da),
                    success: function (res) {
                        _this.unitList = res.data;
                    }
                });
            }, selectUnit() {
                //清除下拉框中的数据
                this.ruleForm.houseId = null;
                this.houseList = [];
                //选中单元
                //查询单元下面的户室信息
                const da = {
                    access_token: localStorage.getItem("token"),
                    unitId: this.ruleForm.unitId
                };
                var _this = this;
                //发送请求
                $.ajax({
                    url: _this.url + "/api-public/opehouse/list",
                    data: qs.stringify(da),
                    type: 'GET',
                    success: function (res) {
                        _this.houseList = res.data;
                    }
                });
            }, submitForm(formName) {
                this.loading = true;
                const _this = this;
                //立即申请按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        _this.$confirm('您确定提交申请吗?', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            $.ajax({
                                url: _this.url + '/api-public/ownerapplay?access_token=' + localStorage.getItem("token"),
                                data: JSON.stringify(_this.ruleForm),
                                type: 'POST',
                                contentType: 'application/json',
                                success: function (res) {
                                    _this.loading = false;
                                    if (res.resp_code == 0) {
                                        //提交成功
                                        _this.$message({
                                            message: res.resp_msg,
                                            type: 'success',
                                            duration: 1000,
                                            onClose: function () {
                                                _this.$router.go(-1);
                                            }
                                        });
                                    }
                                    if (res.resp_code == 1) {
                                        //提交成功
                                        _this.$message({
                                            message: res.resp_msg,
                                            type: 'error',
                                            duration: 1000
                                        });
                                    }
                                }
                            });
                        }).catch(() => {
                            _this.loading = false;
                        });
                    } else {
                        _this.loading = false;
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>